import React from "react"

import { Frame } from "../../layout"
import { H1, Detail } from "../../elements"
import { ArticleList, Breadcrumb, Pagination, Header } from "../../compositions"
import { SEO } from "../../helpers"
import { graphql } from "gatsby"
import {
  ArticleListQuery,
  GraphCms_Article,
  GraphCms_Page,
} from "../../../../graphql-types"

export const query = graphql`
  query ArticleList(
    $skip: Int!
    $limit: Int!
    $regex: String!
    $pathname: String!
  ) {
    graphCmsPage(url: { pathname: { eq: $pathname } }) {
      ...PageFields
    }
    allGraphCmsArticle(
      sort: { fields: createdAt, order: DESC }
      filter: { page: { url: { pathname: { regex: $regex } } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ArticleFields
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`

type Props = {
  data: ArticleListQuery
  pageContext: any
}

const Articles = ({ data }: Props) => {
  const page = data.graphCmsPage as GraphCms_Page
  const { pageInfo, nodes } = data.allGraphCmsArticle

  return (
    <Frame>
      <SEO page={page} />
      <Header className="z-10" />
      <div className="bg-gradient">
        <div className="relative max-w-screen-lg mx-auto py-3 px-4">
          <Breadcrumb breadcrumb={page.breadcrumb} />
        </div>
      </div>

      <div className="relative z-0 max-w-screen-lg mx-auto px-3 sm:px-4 py-16">
        <section className="pt-5 mb-14">
          <H1 className="text-5xl AvenirLTProBlackOblique mb-3">
            Yurt Dışında Yaşamak için Bilmeniz Gerekenler
          </H1>

          <Detail className="text-lg text-gray-dark max-w-screen-sm">
            Yurt dışında yaşamaya karar vermeden önce bilmemiz gereken bazı
            önemli bilgileri sizler için topladık.
          </Detail>

          <ArticleList
            articles={nodes as GraphCms_Article[]}
            className="mt-10"
          />
        </section>

        <Pagination pageInfo={pageInfo} baseUrl={page.url!} />
      </div>
    </Frame>
  )
}

export default Articles
